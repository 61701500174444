// @External Dependencies
import React from 'react'
// import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Jumbotron } from 'reactstrap'

// @Dependencies
import StoreMenu from './StoreMenu'
import { LoadingSVG, PaginationList, Select } from '../../Components/UI'
import {
  getStoreInfo,
  getTournamentsList,
  getSeasons
} from '../../Redux/Actions'
import { axiosAbort } from '../../Services'

import TournamentList from '../Tournaments/TournamentList'

// @Component
class StoreTournamentsView extends React.Component {
  state = {
    alertContent: '',
    buttonBack: '',
    loading: true,
    havePermissions: false,
    Store: {},
    isAdmin: false,

    loadingMessage: 'Estamos buscando los torneos...',
    disableStore: false,
    activeTournaments: true,
    seasons: [],
    seasonId: 0,
    gameId: 0,
    data: {
      total: 0,
      pages: 0,
      Tournaments: [],
      Games: [],
      Formats: [],
      Types: [],
      Stores: [],
      Countries: [],
      Regions: []
    },
    filters: {
      page: 1,
      limit: 9,
      formatId: 0,
      typeId: 0,
      storeId: 0,
      countryId: 0,
      regionId: 0,
      search: '',
      date: 'DESC',
      players: 'DESC',
      statusId: [1, 2, 3, 4, 5, 6, 7, 8]
    }
  }

  /**
   * - Al iniciar busca los paises
   * - Si estoy logueado entonces me manda al home
   */
  async componentDidMount () {
    const { match, history, loggedUser } = this.props
    if (match.params.storeId) {
      const { Store, status } = await this.props.getStoreInfo(
        match.params.storeId
      )
      if (status.success) {
        let havePermissions = false
        if (loggedUser.user && loggedUser.user.Store) {
          if (
            Store.id === loggedUser.user.Store.id &&
            loggedUser.user.Store.active
          ) {
            havePermissions = true
          }
        }

        this.setState({
          Store,
          havePermissions,
          loading: false,
          isAdmin: loggedUser.isAdmin
        })

        this.search()
      } else {
        this.setState({
          alertContent: 'No se encontraron datos de la tienda.',
          buttonBack: '/ranking'
        })
      }
    } else {
      history.push('/stores')
    }
  }
  componentWillUnmount () {
    axiosAbort()
  }

  /**
   * Paginación
   */
  handlePagination = async event => {
    const { filters } = this.state
    if (filters.page !== event) {
      filters.page = event
      this.setState({ filters })
      await this.search()
    }
  }

  /**
   * Actualiza el estado de los torneos
   */
  updateTournaments = async activeTournaments => {
    this.setState({
      activeTournaments: !activeTournaments,
      filters: {
        ...this.state.filters,
        page: 1,
        statusId: !activeTournaments ? [1, 2, 3, 4, 5] : [6, 7, 8]
      }
    })
    await this.search()
  }

  /**
   * Trigger de búsqueda en componente
   */
  async search () {
    const { getTournamentsList } = this.props
    const { filters, Store } = this.state

    filters.storeId = Store.id

    this.setState({ loading: true })
    const tournaments = await getTournamentsList(
      this.state.seasonId,
      filters,
      this.state.gameId
    )

    if (tournaments.status.success) {
      const seasonsMap = tournaments.Seasons.reduce((
        acc,
        { id, name, isActive }
      ) => {
        acc.push({ id, name: `${isActive ? '✅ ' : '⚠️ '}${name}` })
        return acc
      }, [{ id: 0, name: '🔎  Todas las temporadas de juego.' }])
      const gamesMap = tournaments.Games.reduce((acc, { id, name }) => {
        acc.push({
          id,
          name
        })
        return acc
      }, [{ id: 0, name: '🔎  Todas los juegos.' }])

      this.setState({
        seasons: seasonsMap,
        data: {
          ...tournaments,
          Games: gamesMap,
          Regions: tournaments.Regions.map(r => {
            return {
              id: r.id,
              name: r.fullName
            }
          })
        }
      })
    }
    this.setState({
      loading: false
    })
  }

  onChange = async e => {
    e.preventDefault()
    const target = e.target
    if (target.id === 'gameId') {
      const gameId = Number(target.value)
      this.setState({ gameId, loading: true }, async () => {
        await this.search()
      })
    }
    if (target.id === 'seasonId') {
      const seasonId = Number(target.value)
      this.setState({ seasonId, loading: true }, async () => {
        await this.search()
      })
    }
  }

  render () {
    const { t } = this.props
    const {
      Store,
      loading,
      buttonBack,
      loadingMessage,
      havePermissions,
      filters,
      data
    } = this.state

    if (loading) {
      return <LoadingSVG message={loadingMessage} buttonBack={buttonBack} />
    }

    return (
      <Wrapper>
        <div className='BaseContent'>
          <div className='BaseLeft' />
          <div className='BaseRight'>
            <div className='BaseRight__Form'>
              <StoreMenu
                store={Store}
                havePermissions={havePermissions}
                match={this.props.match}
                t={t}
              />
              <Jumbotron>
                <h1>{`${Store.name}`}</h1>
                <p>
                  Acá puedes ver todos los torneos realizados por la tienda, o
                  filtrarlos por temporada.
                </p>
                <div className='grid'>
                  <Select
                    name='gameId'
                    options={this.state.data.Games}
                    select={this.state.gameId}
                    onChange={this.onChange}
                    inputSize={12}
                    noDefaultValue={true}
                  />
                  {this.state.gameId > 0 && (
                    <Select
                      name='seasonId'
                      options={this.state.seasons}
                      select={this.state.seasonId}
                      onChange={this.onChange}
                      inputSize={12}
                      noDefaultValue={true}
                    />
                  )}
                </div>
                {data.Tournaments.length > 0 ? (
                  <>
                    <TournamentList
                      tournaments={data.Tournaments}
                      total={data.total}
                      md={4}
                    />
                    <PaginationList
                      active={filters.page}
                      pages={data.pages}
                      total={data.total}
                      handle={this.handlePagination}
                      centered
                    />
                  </>
                ) : (
                  <>
                    <h4> La tienda aún no registra torneos. </h4>
                  </>
                )}
              </Jumbotron>
            </div>
          </div>
        </div>
      </Wrapper>
    )
  }
}

// @Proptypes
StoreTournamentsView.propTypes = {
  loggedUser: PropTypes.object
}

/*
  @Store Connection: connect
  @Export Component
*/
const mapStateToProps = state => ({
  loggedUser: state.loggedUser,
  countries: state.countries
})
const mapDispatchToProps = dispatch =>
  bindActionCreators({ getStoreInfo, getTournamentsList, getSeasons }, dispatch)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(StoreTournamentsView))

// @Styles
const Wrapper = styled.div.attrs({ className: 'Login' })`
  height: inherit;
`
