// @External Dependencies
import React, { Component } from 'react'
// import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Jumbotron } from 'reactstrap'
// @Dependencies
import { UserRecoverPasswd } from '../../Redux/Actions'
import { Alert, Button, Input } from '../../Components/UI'
import { axiosAbort } from '../../Services'

// @Component
class UserRecover extends Component {
  state = {
    alertContent: '',
    alertState: 'warning',
    loading: false
  }

  componentDidMount () {
    const { loggedUser, history } = this.props
    !loggedUser && !loggedUser.user && history.push('/ranking')
  }

  componentWillUnmount () {
    axiosAbort()
  }

  handleDismissAlert = () => {
    this.setState({ alertContent: '' })
  }

  handleLogin = async event => {
    event.preventDefault()
    const form = new window.FormData(event.target)
    const search = form.get('search').trim()

    let alertState = 'info'
    this.setState({
      alertContent: 'Buscando...',
      alertState,
      loading: true
    })

    const recoverPasswd = await this.props.UserRecoverPasswd(search)
    let alertContent = ''
    if (!recoverPasswd.status.success) {
      alertContent = recoverPasswd.status.detail
      alertState = 'danger'
    } else {
      alertContent = recoverPasswd.status.name
      alertState = 'success'
    }
    this.setState({
      alertContent,
      alertState,
      loading: false
    })
  }

  render () {
    const { loading, alertContent, alertState } = this.state
    return (
      <Wrapper>
        <div className='BaseContent'>
          <div className='BaseLeft' />

          <div className='BaseRight'>
            <Jumbotron>
              <div className='BaseRight__Form'>
                <h1 className='text-center'>Recuperar contraseña</h1>
                <p>
                  Si no recuerdas tu contraseña, puedes reiniciarla ingresando
                  tu código TOR o tu correo electrónico registrado.
                </p>
                <p>
                  Ten en cuenta que{' '}
                  <b>al email registrado se enviará un link</b> para que puedas
                  reiniciar tu contraseña. Si no tienes acceso a ese email,
                  deberás ponerte en contacto con un administrador para que
                  actualice tus datos.
                </p>
                <form
                  onSubmit={this.handleLogin}
                  acceptCharset='utf-8'
                  id='UserLoginForm'>
                  {alertContent.length > 0 && (
                    <Alert color={alertState} close={this.handleDismissAlert}>
                      {alertContent}
                    </Alert>
                  )}
                  <Input
                    icon='single-01'
                    disabled={loading}
                    inputSize={12}
                    name='search'
                    placeholder='Código TOR o Email.'
                    type='text'
                    required
                  />
                </form>

                <div className='BaseRight__Bottom__actions'>
                  <Button
                    id='btn_login1'
                    disabled={loading}
                    type='submit'
                    text='Recuperar contraseña'
                    state='primary'
                    form='UserLoginForm'
                    className='btn-login'
                  />
                  <div className='flex'>
                    <Link to='/login' className='btn btn-link'>
                      <i className='fa fa-sign-in' /> Ingresar al sistema
                    </Link>
                    <Link to='/register' className='btn btn-link'>
                      <i className='fa fa-user-plus' /> Regístrate acá
                    </Link>
                  </div>
                </div>
              </div>
            </Jumbotron>
          </div>
        </div>
      </Wrapper>
    )
  }
}

// @Proptypes
UserRecover.propTypes = {
  loggedUser: PropTypes.object
}

/*
  @Store Connection: connect
  @Export Component
*/

const mapStateToProps = state => ({ loggedUser: state.loggedUser })
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      UserRecoverPasswd
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UserRecover))

// @Styles
const Wrapper = styled.div.attrs({ className: 'Recover' })`
  height: inherit;
`
