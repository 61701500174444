// @External Dependencies
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Jumbotron, Table } from 'reactstrap'
import moment from 'moment'
import 'moment/locale/es'
// @Dependencies
import { getUser } from '../../Redux/Actions'
import { LoadingSVG, Avatar } from '../../Components/UI'

// @Component
class ProfileView extends Component {
  state = {
    alertContent: 'Cargando...',
    loading: true
  }

  async componentDidMount () {
    const { getUser } = this.props
    await getUser()
    this.setState({
      loading: false
    })
  }

  render () {
    const { loggedUser, t } = this.props
    const { loading, alertContent } = this.state
    if (loading) return <LoadingSVG message={alertContent} />
    const { user } = loggedUser
    const fullAddress = [
      user.Address.address,
      user.Address.Region.fullName,
      user.Address.Location ? user.Address.Location.name : '',
      user.Address.Country.name
    ].join(' ')
    return (
      <Wrapper>
        <div className='BaseContent'>
          <div className='BaseLeft' />

          <div className='BaseRight'>
            <div className='BaseRight__Form'>
              <Jumbotron>
                <h1 className='text-center'>
                  <Avatar person={user} /> Perfil jugador!
                </h1>
                <Table className='table-bordered'>
                  <thead>
                    <tr>
                      <th colSpan={2}>
                        <i className='fa fa-user-circle' />
                        {' Información'}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope='row'>Nombre</th>
                      <td>{user.fullName}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Código</th>
                      <td>
                        <span className='btn btn-primary btn-sm'>
                          {user.code}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th scope='row'>Género</th>
                      <td>{user.Gender.name}</td>
                    </tr>
                    {user.birthday !== '0000-00-00' && (
                      <tr>
                        <th scope='row'>Cumpleaños</th>
                        <td>
                          {moment(user.birthday).format(
                            'dddd DD \\d\\e MMMM \\d\\e\\l Y'
                          )}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th colSpan={2}>
                        <i className='fa fa-map-marker' />
                        {' Datos de Contacto'}
                      </th>
                    </tr>
                    <tr>
                      <th scope='row'>Email</th>
                      <td>
                        {user.email}
                        {!user.emailValid && (
                          <span className='text-danger'>{` (No validado)`}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope='row'>Teléfono</th>
                      <td>{user.phone}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Dirección</th>
                      <td>{fullAddress}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Registrado</th>
                      <td>
                        {moment(user.created).format(
                          'dddd DD \\d\\e MMMM \\d\\e\\l Y \\a \\l\\a\\s HH:m \\h\\r\\s.'
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <p className='lead'>
                  <Link className='btn btn-primary' to='/profile/update'>
                    <i className='fa fa-edit' /> {t('Actualizar mi perfil')}
                  </Link>
                </p>
              </Jumbotron>
            </div>
          </div>
        </div>
      </Wrapper>
    )
  }
}

// @Proptypes
ProfileView.propTypes = {
  loggedUser: PropTypes.object
}

/*
  @Store Connection: connect
  @Export Component
*/

const mapStateToProps = state => ({
  loggedUser: state.loggedUser
})
const mapDispatchToProps = dispatch => bindActionCreators({ getUser }, dispatch)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ProfileView))

// @Styles
const Wrapper = styled.div.attrs({ className: 'Profile' })`
  height: inherit;
  .ProfileContent {
    h1,
    p,
    a {
      text-align: center;
    }
    a {
      display: block;
      width: 200px;
      margin: 0 auto;
    }
  }
`
