// @External Dependencies
import React, { Component } from 'react'
// import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Jumbotron } from 'reactstrap'
// @Dependencies
import { UserLogin, getUser } from '../../Redux/Actions'
import { Alert, Button, Input } from '../../Components/UI'
import { axiosAbort } from '../../Services'

// @Component
class Login extends Component {
  state = {
    alertContent: '',
    alertState: 'warning',
    loading: false,
    email: '',
    passwd: ''
  }

  componentDidMount () {
    const { loggedUser, history, location } = this.props
    loggedUser && loggedUser.user && history.push('/ranking')

    setTimeout(() => {
      window.localStorage.removeItem('persist:myl')
    }, 500)

    const PrivateRoute =
      location.state !== undefined ? location.state.protected : ''

    const action = history.action

    if (window.sessionStorage.getItem('session') === 'expired') {
      this.setState({
        alertContent:
          'Tu sesión ha expirado. Favor volver a ingresar con tus credenciales.'
      })
    } else {
      if (PrivateRoute && action === 'REPLACE') {
        this.setState({
          alertContent:
            'Para ver esta sección debes estar logueado en el sistema.',
          alertState: 'danger'
        })
      }
    }
  }

  componentWillUnmount () {
    axiosAbort()
  }

  handleDismissAlert = () => {
    this.setState({ alertContent: '' })
  }

  onChange = e => {
    e.preventDefault()
    const { target } = e
    let { passwd, email } = this.state
    if (target.id === 'email') {
      email = target.value.trim()
    }

    if (target.id === 'passwd') {
      passwd = target.value.trim()
    }

    this.setState({ email, passwd })
  }

  handleLogin = async event => {
    event.preventDefault()
    const { UserLogin, history, getUser } = this.props

    const { email, passwd } = this.state

    if (email) {
      this.setState({ alertContent: '', loading: true })

      // Armar data para enviar Form
      const data = {
        username: email,
        passwd: passwd,
        type: 'EMAIL'
      }
      const loginInfo = await UserLogin(data)
      if (!loginInfo.status.success) {
        this.setState({
          alertContent: loginInfo.status.detail,
          loading: false
        })
      } else {
        const user = await getUser()
        if (user === 'SET_USER_OK') {
          window.sessionStorage.removeItem('session')
          const lastURL = window.sessionStorage.getItem('lastURL')
          const { loggedUser } = this.props

          if (loggedUser) {
            const protectedRoute =
              (history.location.state &&
                history.location.state.from.pathname) ||
              undefined
            if (lastURL) {
              history.push(lastURL)
              window.sessionStorage.removeItem('lastURL')
            } else if (protectedRoute) {
              history.push(protectedRoute)
            } else {
              history.push('/ranking')
            }
          }
        }
        this.setState({ loading: false })
      }
    }
  }

  render () {
    const { loading, alertContent, alertState } = this.state
    const { t } = this.props
    return (
      <Wrapper>
        <div className='BaseContent'>
          <div className='BaseLeft' />

          <div className='BaseRight'>
            <Jumbotron>
              <div className='BaseRight__Form'>
                <h1 className='text-center'>{t('Identifícate')}</h1>
                <h4 className='text-center'>
                  {t(
                    'Para ingresar al sistema completa los datos del formulario.'
                  )}
                </h4>
                <form acceptCharset='utf-8' id='UserLoginForm'>
                  {alertContent.length > 0 && (
                    <Alert state={alertState} close={this.handleDismissAlert}>
                      {alertContent}
                    </Alert>
                  )}
                  <Input
                    disabled={loading}
                    inputSize={10}
                    type='email'
                    label='Email'
                    name='email'
                    value={this.state.email}
                    onChange={this.onChange}
                    required
                  />
                  <Input
                    icon='lock'
                    disabled={loading}
                    inputSize={10}
                    name='passwd'
                    label={'Contraseña'}
                    placeholder={t('Contraseña')}
                    type='password'
                    onChange={this.onChange}
                    value={this.state.passwd}
                    required
                  />
                </form>
                <div className='BaseRight__Bottom__actions'>
                  <Button
                    id='btn_login1'
                    disabled={
                      loading ||
                      !this.state.email.length ||
                      !this.state.passwd.length
                    }
                    type='submit'
                    text={'Ingresar'}
                    state='primary'
                    onClick={this.handleLogin}
                    style={{ height: '50px' }}
                  />

                  <div className='flex'>
                    <Link to='/recover' className='btn btn-link'>
                      <i className='fa fa-question-circle-o' />{' '}
                      {t('Recuperar contraseña')}
                    </Link>
                    <Link to='/register' className='btn btn-link'>
                      <i className='fa fa-user-plus' /> {t('Regístrate acá')}
                    </Link>
                  </div>
                </div>
              </div>
            </Jumbotron>
          </div>
        </div>
      </Wrapper>
    )
  }
}

// @Proptypes
Login.propTypes = {
  loggedUser: PropTypes.object
}

/*
  @Store Connection: connect
  @Export Component
*/

const mapStateToProps = state => ({ loggedUser: state.loggedUser })
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      UserLogin,
      getUser
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Login))

// @Styles
const Wrapper = styled.div.attrs({ className: 'Login' })`
  height: inherit;
  .username .InputUI {
    margin-left: 10px;
  }
  @media screen and (max-width: 480px) {
    .username .InputUI {
      margin-left: 0px;
    }
    .BaseRight__Bottom__actions {
      button {
        width: 100%;
      }
    }
  }
`
