// @External Dependencies
import React, { Component } from 'react'
// import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Jumbotron, Col, Table } from 'reactstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'

import {
  UserEdit,
  getUser,
  getCountries,
  getRegions,
  getLocations,
  UploadImage,
  FindPerson
} from '../../../Redux/Actions'
import {
  Alert,
  Button,
  Input,
  Select,
  Datapicker,
  LoadingSVG,
  Avatar
} from '../../../Components/UI'
import { axiosAbort } from '../../../Services'

// @Component
class UserUpdateView extends Component {
  state = {
    alertContent: 'Cargando...',
    alertState: 'warning',
    loading: true,
    person: {
      id: 0,
      name: '',
      firstSurname: '',
      lastSurname: '',
      email: '',
      active: 1,
      nickname: '',
      code: '',
      birthday: '0000-00-00',
      passwd: '',
      phone: '',
      genderId: 0,
      avatar: '',
      urlAvatar: '',
      categoryId: 0
    },
    address: {
      id: 0,
      name: 'Personal',
      address: '',
      countryId: 0,
      regionId: 0,
      locationId: 0
    },
    categories: [
      { id: 1, name: 'Casual' },
      { id: 2, name: 'Social' },
      { id: 3, name: 'Competitivo' },
      { id: 4, name: 'Aprendiz' }
    ],
    genders: ['Hombre', 'Mujer', 'Otro'],
    countries: [],
    regions: [],
    locations: [],
    activeUser: [
      { id: 1, name: '✅ Jugador activo' },
      { id: 2, name: '⛔️ Jugador sancionado' }
    ],
    isAdmin: false
  }

  /**
   * - Al iniciar busca los paises
   * - Si no estoy logueado entonces me manda al home
   */
  async componentDidMount () {
    const {
      loggedUser,
      history,
      getCountries,
      getRegions,
      getLocations,
      getUser,
      FindPerson,
      match: { params }
    } = this.props
    await getUser()
    const { isAdmin } = loggedUser
    if (!loggedUser && !loggedUser.user) {
      history.push('/ranking')
    }

    let countries = await getCountries()
    let regions
    let locations

    let editUser
    if (isAdmin && params.personId && params.personId !== loggedUser.user.id) {
      const { Person } = await FindPerson(params.personId)
      regions = await getRegions(Person.Address.countryId)
      locations = await getLocations(Person.Address.regionId)
      editUser = Person
    } else {
      regions = await getRegions(loggedUser.user.Address.countryId)
      locations = await getLocations(loggedUser.user.Address.regionId)
      editUser = loggedUser.user
    }

    this.setState({
      countries,
      regions: regions.map(({ id, fullName }) => {
        return {
          id: id,
          name: fullName
        }
      }),
      locations,
      person: editUser,
      address: editUser.Address,
      loading: false,
      alertContent: '',
      isAdmin
    })
  }

  componentWillUnmount () {
    axiosAbort()
  }

  handleDismissAlert = () => {
    this.setState({ alertContent: '' })
  }

  handleChange = e => {
    this.setState({ genderId: e.target.value })
  }

  onSubmit = async event => {
    event.preventDefault()
    const { UserEdit, history } = this.props
    const { person, address } = this.state
    person.birthday = new window.FormData(event.target).get('birthday').trim()

    this.setState({
      alertContent: 'Actualizando datos de jugador.',
      loading: true
    })

    // Armar data para enviar Form
    const data = {
      person,
      address
    }

    const resolver = await UserEdit(data)
    if (!resolver.status.success) {
      this.setState({
        alertContent: resolver.status.detail,
        loading: false
      })
    } else {
      /**
       * Si registra al usuario entonces lo logueamos en el sistema.
       */
      this.setState({
        alertContent: resolver.status.name,
        alertState: 'success'
      })

      if (!this.props.loggedUser.isAdmin) {
        setTimeout(async () => {
          history.push('/profile')
        }, 3000)
      } else {
        setTimeout(async () => {
          history.push('/users')
        }, 3000)
      }
    }
  }

  /**
   * Maneja los cambios de los inputs
   */
  handleChange = async e => {
    const { address, person } = this.state
    const target = e.target

    /**
     * Datos Persona
     */
    if (target && target.id === 'name') {
      person.name = target.value.trim()
    }
    if (target && target.id === 'firstSurname') {
      person.firstSurname = target.value.trim()
    }
    if (target && target.id === 'lastSurname') {
      person.lastSurname = target.value.trim()
    }
    if (target && target.id === 'birthday') {
      person.birthday = target.value.trim()
    }
    if (target && target.id === 'genderId') {
      person.genderId = Number(target.value)
    }
    if (target && target.id === 'categoryId') {
      person.categoryId = Number(target.value)
    }
    if (target && target.id === 'active') {
      person.active = Number(target.value)
    }
    if (target && target.id === 'email') {
      person.email = target.value.trim()
    }

    if (target && target.id === 'phone') {
      person.phone = target.value.trim()
    }
    if (target && target.id === 'passwd') {
      person.passwd = target.value
    }

    /**
     * Actualiza datos de dirección
     */
    if (target && target.id === 'addressName') {
      address.name = target.value.trim()
    }
    if (target && target.id === 'address') {
      address.address = target.value.trim()
    }
    if (target && target.id === 'countryId') {
      const countryId = Number(target.value)
      address.countryId = countryId
      address.regionId = 0
      address.locationId = 0
      const regions = await this.props.getRegions(countryId)
      this.setState({
        regions: regions.map(r => {
          return {
            id: r.id,
            name: r.fullName
          }
        }),
        locations: []
      })
    }

    if (target && target.id === 'regionId') {
      const regionId = Number(target.value)
      address.regionId = regionId
      address.locationId = 0
      const locations = await this.props.getLocations(regionId)
      this.setState({
        locations
      })
    }

    if (target && target.id === 'locationId') {
      const locationId = Number(target.value)
      address.locationId = locationId
    }

    this.setState({
      person,
      address
    })
  }
  uploadImage = e => {
    const files = e.target.files
    const file = files[0]
    if (['image/jpeg', 'image/png'].includes(file.type)) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async e => {
        const params = {
          file: e.target.result,
          name: files[0].name,
          type: 'user.avatar'
        }
        if (this.props.match.params.personId) {
          params.personId = Number(this.props.match.params.personId)
        }
        this.setState({
          loading: true,
          alertContent: 'Actualizando imagen de perfil.'
        })
        const response = await this.props.UploadImage(params)

        if (response.status.success) {
          this.setState({
            person: {
              ...this.state.person,
              urlAvatar: response.Person.urlAvatar
            }
          })
        }
        setTimeout(() => {
          this.setState({
            loading: false,
            alertContent: response.status.name,
            alertState: response.status.success ? 'success' : 'warning'
          })
        }, 1500)
      }
    } else {
      alert('Error en formato, sólo se permiten JPG o PNG')
    }
  }

  render () {
    const {
      loading,
      alertContent,
      alertState,
      countries,
      regions,
      locations,
      genders,
      person,
      address,
      isAdmin
    } = this.state
    const { t } = this.props
    if (loading) return <LoadingSVG message={alertContent} />
    return (
      <Wrapper>
        <div className='BaseContent'>
          <div className='BaseLeft' />
          <div className='BaseRight'>
            {isAdmin && (
              <Link to='/users' className='padding-20'>
                <i className='fa fa-arrow-left' /> Listado de usuarios
              </Link>
            )}
            <Jumbotron>
              <div className='BaseRight__Form'>
                <header className='HeaderProfile'>
                  <Col>
                    <h1>Actualización de Perfil</h1>
                    <Table
                      className={`table-bordered table-hover ${
                        this.props.isMobile ? 'table-responsive' : ''
                      }`}>
                      <tbody>
                        <tr>
                          <th>Nombre jugador</th>
                          <td>
                            {person.name} {person.firstSurname}{' '}
                            {person.lastSurname}
                          </td>
                        </tr>
                        <tr>
                          <th>Código Jugador</th>
                          <td>{person.code}</td>
                        </tr>
                        <tr>
                          <th>Estado</th>
                          <td>
                            {person.active === 1
                              ? '✅ Jugador activo'
                              : '⛔️ Jugador sancionado'}
                          </td>
                        </tr>
                        <tr>
                          <th>Organizador de torneos</th>
                          <td>
                            {person.Store ? ' Si - ' : 'No'}
                            {person.Store && person.Store.active && (
                              <Link to={person.Store.urlView}>
                                {person.Store.name}
                              </Link>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col>
                    <span className='ImagenAvatar'>
                      <Avatar person={person} size={240} fontSize={90} />
                    </span>
                  </Col>
                </header>
                <form
                  onSubmit={this.onSubmit}
                  acceptCharset='utf-8'
                  id='UserEditForm'>
                  {alertContent.length > 0 && (
                    <Alert color={alertState} close={this.handleDismissAlert}>
                      {alertContent}
                    </Alert>
                  )}
                  <Col>
                    <h2>Datos Personales</h2>
                    <p>Actualiza los datos personales de jugador.</p>

                    <Input
                      disabled={loading || !isAdmin}
                      labelSize={3}
                      inputSize={9}
                      label={t('Nombre')}
                      name='name'
                      placeholder={t('Nombre')}
                      value={person.name}
                      onChange={this.handleChange}
                      type='input'
                      required
                    />
                    <Input
                      disabled={loading || !isAdmin}
                      labelSize={3}
                      inputSize={9}
                      label={t('Primer apellido')}
                      name='firstSurname'
                      placeholder={t('Primer Apellido')}
                      value={person.firstSurname}
                      onChange={this.handleChange}
                      type='input'
                      required
                    />
                    <Input
                      disabled={loading || !isAdmin}
                      labelSize={3}
                      inputSize={9}
                      label={t('Segundo Apellido')}
                      name='lastSurname'
                      placeholder={t('Segundo Apellido')}
                      value={person.lastSurname}
                      onChange={this.handleChange}
                      type='input'
                      required
                    />
                    <Input
                      disabled={loading}
                      labelSize={3}
                      inputSize={9}
                      label={t('Avatar')}
                      name='avatar'
                      placeholder={t('Avatar')}
                      value=''
                      onChange={this.uploadImage}
                      type='file'
                    />
                    <Datapicker
                      disabled={loading}
                      name='birthday'
                      label={t('Fecha Nacimiento')}
                      birthDate
                      labelSize={3}
                      inputSize={9}
                      required
                      value={person.birthday}
                    />

                    <Select
                      disabled={loading}
                      name='genderId'
                      options={genders}
                      label={t('Género')}
                      select={person.genderId}
                      onChange={this.handleChange}
                      labelSize={3}
                      inputSize={9}
                      required
                    />
                    <Select
                      disabled={loading}
                      name='categoryId'
                      options={this.state.categories}
                      label={t('Categoría Jugador')}
                      select={person.categoryId}
                      onChange={this.handleChange}
                      labelSize={3}
                      inputSize={9}
                      required
                    />

                    <Input
                      disabled={loading}
                      labelSize={3}
                      inputSize={9}
                      name='passwd'
                      placeholder={t('contraseña')}
                      type='password'
                      label={t('Contraseña')}
                      onChange={this.handleChange}
                    />
                    <Input
                      disabled={loading}
                      labelSize={3}
                      inputSize={9}
                      name='email'
                      placeholder={t('Email')}
                      type='email'
                      label={t('Email')}
                      value={person.email}
                      onChange={this.handleChange}
                      required
                    />
                    <Input
                      disabled={loading}
                      labelSize={3}
                      inputSize={9}
                      name='phone'
                      placeholder={t('Teléfono')}
                      type='input'
                      label={t('Teléfono')}
                      onChange={this.handleChange}
                      value={person.phone}
                      required
                    />
                  </Col>
                  <Col>
                    <h2>Dirección</h2>
                    <p>
                      Actualiza tu dirección. Los cambios se actualizarán si tu
                      último cambio de dirección fue hace al menos 6 meses.
                    </p>
                    <p>
                      <small>
                        <i>Última actualización: </i>
                        {moment(address.modified).format(
                          'dddd DD \\d\\e MMMM YYYY'
                        )}
                      </small>
                    </p>
                    <Input
                      disabled={loading}
                      inputSize={9}
                      labelSize={3}
                      name='address'
                      placeholder={t('Dirección')}
                      type='text'
                      label={t('Dirección')}
                      value={address.address}
                      onChange={this.handleChange}
                      required
                    />
                    <Select
                      disabled={loading}
                      name='countryId'
                      options={countries}
                      label={t('País')}
                      onChange={this.handleChange}
                      inputSize={9}
                      labelSize={3}
                      select={address.countryId}
                      required
                    />
                    <Select
                      disabled={loading}
                      name='regionId'
                      options={regions}
                      label={t('Región')}
                      onChange={this.handleChange}
                      inputSize={9}
                      labelSize={3}
                      select={address.regionId}
                      required
                    />
                    {locations.length > 0 && (
                      <Select
                        disabled={loading}
                        name='locationId'
                        options={locations}
                        label={t('Comuna')}
                        onChange={this.handleChange}
                        inputSize={9}
                        labelSize={3}
                        select={address.locationId}
                        required
                      />
                    )}
                  </Col>
                  {isAdmin && (
                    <Col>
                      <h2>Sección administración</h2>
                      <p>
                        Acá puedes sancionar o desactivar la cuenta del usuario
                      </p>
                      <Select
                        disabled={loading}
                        name='active'
                        options={this.state.activeUser}
                        label={t('Estado del jugador')}
                        onChange={this.handleChange}
                        inputSize={9}
                        labelSize={3}
                        select={person.active}
                        required
                      />
                    </Col>
                  )}
                </form>

                <div className='BaseRight__Bottom__actions buttons'>
                  <Button
                    disabled={loading}
                    type='submit'
                    text={t('Actualizar Datos')}
                    state='primary'
                    form='UserEditForm'
                    className='btn-login'
                    style={{ margin: 'auto' }}
                  />
                </div>
              </div>
            </Jumbotron>
          </div>
        </div>
      </Wrapper>
    )
  }
}

// @Proptypes
UserUpdateView.propTypes = {
  loggedUser: PropTypes.object
}

/*
  @Store Connection: connect
  @Export Component
*/

const mapStateToProps = state => ({
  loggedUser: state.loggedUser,
  countries: state.countries
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      UserEdit,
      getUser,
      getCountries,
      getRegions,
      getLocations,
      UploadImage,
      FindPerson
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UserUpdateView))

// @Styles
const Wrapper = styled.div.attrs({ className: 'Login' })`
  height: inherit;
  .HeaderProfile {
    display: grid;
    grid-template-columns: 3fr 1fr;
  }
  .padding-20 {
    padding: 20px;
  }
`
