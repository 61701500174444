// @External Dependencies
import React from 'react'
// import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Jumbotron, Table } from 'reactstrap'
import ReactCountryFlag from 'react-country-flag'
import ReactHtmlParser from 'react-html-parser'
// @Dependencies
import StoreMenu from './StoreMenu'
import { LoadingSVG } from '../../Components/UI'
import { getStoreInfo, StoreDelete } from '../../Redux/Actions'
import { axiosAbort } from '../../Services'

// @Component
class StoreDetailView extends React.Component {
  state = {
    alertContent: '',
    buttonBack: '',
    loading: true,
    havePermissions: false,
    Store: {},
    isAdmin: false
  }

  /**
   * - Al iniciar busca los paises
   * - Si estoy logueado entonces me manda al home
   */
  async componentDidMount () {
    const { match, history, loggedUser } = this.props
    if (match.params.storeId) {
      const { Store, status } = await this.props.getStoreInfo(
        match.params.storeId
      )
      if (status.success) {
        let havePermissions = false
        if (loggedUser.user && loggedUser.user.Store) {
          if (
            Store.id === loggedUser.user.Store.id &&
            loggedUser.user.Store.active
          ) {
            havePermissions = true
          }
        }
        this.setState({
          Store,
          havePermissions,
          loading: false,
          isAdmin: loggedUser.isAdmin
        })
      } else {
        this.setState({
          alertContent: 'No se encontraron datos de la tienda.',
          buttonBack: '/ranking'
        })
      }
    } else {
      history.push('/ranking')
    }
  }

  onDelete = async e => {
    e.preventDefault()
    const { Store } = this.state
    this.setState({ loading: true })
    const { status } = await this.props.StoreDelete(Store.id)
    if (status.success) {
      setTimeout(() => {
        this.props.history.push('/stores')
      }, 4000)
    } else {
      this.setState({
        alertContent: status.detail,
        buttonBack: '/stores'
      })
    }
  }

  componentWillUnmount () {
    axiosAbort()
  }

  render () {
    const { t, isMobile } = this.props
    const {
      Store,
      loading,
      buttonBack,
      alertContent,
      havePermissions,
      isAdmin
    } = this.state

    if (loading)
      return <LoadingSVG message={alertContent} buttonBack={buttonBack} />

    const fullAddress = [
      Store.Address.address,
      Store.Address.Region.name,
      Store.Address.Location ? Store.Address.Location.name : ''
    ]
      .join(', ')
      .replace(', ,', ', ')

    return (
      <Wrapper>
        <div className='BaseContent'>
          <div className='BaseLeft' />
          <div className='BaseRight'>
            <div className='BaseRight__Form'>
              <StoreMenu
                store={Store}
                havePermissions={havePermissions}
                match={this.props.match}
                t={t}
              />
              <Jumbotron>
                <div className='HeaderStore'>
                  <div className='StoreDetail'>
                    <h1>{`${Store.name}`}</h1>
                    <Table
                      className={`table-bordered table-hover ${
                        isMobile ? 'table-responsive' : ''
                      }`}>
                      <tbody>
                        <tr>
                          <th>
                            <b>Perfil usuario dueño de la tienda</b>{' '}
                          </th>
                          <td>
                            {Store.Person.fullName}
                            <br />
                            Código: {Store.Person.code}
                          </td>
                        </tr>
                        <tr>
                          <th>Contacto principal</th>
                          <td>{Store.inChargePerson}</td>
                        </tr>
                        <tr>
                          <th>Dirección</th>
                          <td>
                            {fullAddress}{' '}
                            <ReactCountryFlag
                              code={Store.Address.Country.iso}
                              svg
                            />{' '}
                          </td>
                        </tr>
                        <tr>
                          <th>Estado</th>
                          <td>
                            {Store.active === 1
                              ? ReactHtmlParser('✅ &nbsp;Activa')
                              : '⚠️ Aún no ha sido validada'}
                          </td>
                        </tr>
                        <tr>
                          <th>Categoría</th>
                          <td>
                            <b>{Store.Level.name}</b>
                            <br />
                            <i>{Store.Level.description}</i>
                          </td>
                        </tr>
                        {Store.StoreTournamentDays.length > 0 && (
                          <tr>
                            <th>Torneos los días</th>
                            <td>
                              <ul>
                                {Store.StoreTournamentDays.map((day, key) => {
                                  return (
                                    <li key={key}>{day.TournamentDay.name}</li>
                                  )
                                })}
                              </ul>
                            </td>
                          </tr>
                        )}
                        {Store.StoreGames.length > 0 ? (
                          <tr>
                            <th>Organiza torneos de</th>
                            <td>
                              <ul>
                                {Store.StoreGames.map((game, key) => {
                                  return <li key={key}>{game.Game.name}</li>
                                })}
                              </ul>
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <th colsPan={2}>
                              Tienda activa pero sin juegos asociados para
                              generar torneos
                            </th>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    <h3>Contacto y Redes Sociales</h3>
                    <ul>
                      <li>
                        <b>Email: </b>
                        {Store.email}
                      </li>
                      <li>
                        <b>Teléfono: </b>
                        {Store.phone}
                      </li>
                      {Store.siteUrl && (
                        <li>
                          <b>Web:</b>
                          <a
                            href={Store.siteUrl}
                            target='_blank'
                            rel='noopener noreferrer'
                            title='Url sitio'>
                            {Store.siteUrl}
                          </a>
                        </li>
                      )}
                      {Store.facebookUrl && (
                        <li>
                          <b>Facebook: </b>
                          <a
                            href={Store.facebookUrl}
                            target='_blank'
                            rel='noopener noreferrer'
                            title='Url Facebook'>
                            {Store.facebookUrl}
                          </a>
                        </li>
                      )}
                      {Store.instagramUrl && (
                        <li>
                          <b>Instagram: </b>
                          <a
                            href={Store.instagramUrl}
                            target='_blank'
                            rel='noopener noreferrer'
                            title='Url Instagram'>
                            {Store.instagramUrl}
                          </a>
                        </li>
                      )}
                      {Store.twitterUrl && (
                        <li>
                          <b>Twitter: </b>
                          <a
                            href={Store.twitterUrl}
                            target='_blank'
                            rel='noopener noreferrer'
                            title='Url twitter'>
                            {Store.twitterUrl}
                          </a>
                        </li>
                      )}
                      {Store.youtubeUrl && (
                        <li>
                          <b>Youtube: </b>
                          <a
                            href={Store.youtubeUrl}
                            target='_blank'
                            rel='noopener noreferrer'
                            title='Url youtube'>
                            {Store.youtubeUrl}
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                  {Store.urlLogo && (
                    <div className='StoreLogo'>
                      <img
                        src={Store.urlLogo}
                        alt={Store.name}
                        className='img-thumbnail'
                      />
                    </div>
                  )}
                </div>
                {(havePermissions || isAdmin) && (
                  <p className='lead'>
                    <Link
                      to={
                        isAdmin ? `/store/update/${Store.id}` : '/store/update'
                      }
                      className='btn btn-primary'>
                      <i className='fa fa-edit' />
                      Actualizar datos
                    </Link>
                    {Store.active === 2 && isAdmin && (
                      <Link
                        className='btn btn-danger'
                        onClick={this.onDelete}
                        to=''>
                        <i className='fa fa-trash' />
                        Eliminar tienda
                      </Link>
                    )}
                    {havePermissions && (
                      <Link
                        to='/tournament/register'
                        className='btn btn-primary'>
                        <i className='fa fa-plus-circle' />
                        Crear torneo
                      </Link>
                    )}
                  </p>
                )}
              </Jumbotron>
            </div>
          </div>
        </div>
      </Wrapper>
    )
  }
}

// @Proptypes
StoreDetailView.propTypes = {
  loggedUser: PropTypes.object
}

/*
  @Store Connection: connect
  @Export Component
*/
const mapStateToProps = state => ({
  loggedUser: state.loggedUser,
  countries: state.countries
})
const mapDispatchToProps = dispatch =>
  bindActionCreators({ getStoreInfo, StoreDelete }, dispatch)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(StoreDetailView))

// @Styles
const Wrapper = styled.div.attrs({ className: 'StoreDetail' })`
  height: inherit;
  .HeaderStore {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
`
