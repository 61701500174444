// @External Dependencies
import React, { Component } from 'react'
// import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Jumbotron } from 'reactstrap'

// @Dependencies
import { TournamentTypeCreate, getUser } from '../../Redux/Actions'
import { Button, LoadingSVG } from '../../Components/UI'
import { axiosAbort } from '../../Services'

import FormTournament from './Form'

// @Component
class TournamentTypeCreateView extends Component {
  state = {
    alertContent: '',
    alertState: 'warning',
    loading: true,
    errors: true,
    TournamentType: {
      name: '',
      description: '',
      status: true,
      private: false,
      minPlayers: 2,
      maxTopPlayers: 0,
      multiplier: 0,
      winnerPoints: 3,
      loserPoints: 0,
      drawPoints: 1,
      socialRequired: false,
      storeTournaments: 0,
      canBeDouble: false
    }
  }

  /**
   * - Al iniciar busca los juegos, formatos, tipos de torneo
   * - Si no tengo permisos para crear torneos entonces mostramos alerta y enviamos al home
   */
  async componentDidMount () {
    await this.props.getUser()
    if (!this.props.loggedUser.isAdmin) {
      this.setState({
        loading: true,
        loadingMessage: 'Solo administradores pueden ver esta sección.',
        buttonBack: '/'
      })
    } else {
      this.setState({ loading: false })
    }
  }

  componentWillUnmount () {
    axiosAbort()
  }

  onSubmit = async event => {
    event.preventDefault()
    const { TournamentTypeCreate, history } = this.props
    const { TournamentType } = this.state

    this.setState({
      alertContent: 'Estamos creando el tipo de torneo torneo...',
      loading: true
    })

    let { errors } = this.state
    /**
     * Si no hay errores de validación entonces cremos el Torneo
     */
    if (!errors) {
      const resolver = await TournamentTypeCreate(TournamentType)
      if (!resolver.status.success) {
        this.setState({
          alertContent: resolver.status.detail
        })
        setTimeout(() => {
          this.setState({ loading: false })
        }, 2000)
      } else {
        /**
         * Si se registra correctamente el torneo entonces redireccionamos a su detalle
         */
        this.setState({
          alertContent: resolver.status.name,
          alertState: 'success',
          errors: true
        })
        setTimeout(() => {
          history.push('/tournament-types')
        }, 3000)
      }
    } else {
      this.setState({
        errors,
        alertContent: 'Hay errores en el formulario',
        loadingRegister: false
      })
    }
  }

  /**
   * Controla los cambios de estados de los inputs
   */
  onChange = async e => {
    const { TournamentType } = this.state
    const target = e.target

    let { errors } = this.state
    if (target && target.id === 'name') {
      TournamentType.name = target.value.trim()
      errors = !(TournamentType.name.length > 0)
    }

    if (target && target.id === 'description') {
      TournamentType.description = target.value.trim()
      errors = !(TournamentType.description.length > 0)
    }

    /**
     * Jugadores
     */
    if (target && target.id === 'minPlayers') {
      if (target.value.length > 0) {
        const minPlayers = Number(target.value)
        errors = minPlayers < 2
        TournamentType.minPlayers = minPlayers
      } else {
        errors = true
      }
    }
    if (target && target.id === 'maxTopPlayers') {
      if (target.value.length > 0) {
        TournamentType.maxTopPlayers = Number(target.value)
      }
    }

    if (target && target.id === 'multiplier') {
      if (target.value.length > 0) {
        TournamentType.multiplier = Number(target.value)
      }
    }

    if (target && target.id === 'storeTournaments') {
      if (target.value.length > 0) {
        TournamentType.storeTournaments = Number(target.value)
      }
    }

    /**
     * Puntajes
     */
    if (target && target.id === 'winnerPoints') {
      if (target.value.length > 0) {
        TournamentType.winnerPoints = Number(target.value)
      }
    }
    if (target && target.id === 'loserPoints') {
      if (target.value.length > 0) {
        TournamentType.loserPoints = Number(target.value)
      }
    }
    if (target && target.id === 'drawPoints') {
      if (target.value.length > 0) {
        TournamentType.drawPoints = Number(target.value)
      }
    }

    this.setState({ TournamentType, errors })
  }

  onChangeModalToggle = e => {
    e.preventDefault()
    const target = e.target
    const { TournamentType } = this.state

    if (target.id === 'status') {
      TournamentType.status = !TournamentType.status
    }

    if (target.id === 'private') {
      TournamentType.private = !TournamentType.private
    }

    if (target.id === 'canBeDouble') {
      TournamentType.canBeDouble = !TournamentType.canBeDouble
    }

    if (target.id === 'socialRequired') {
      TournamentType.socialRequired = !TournamentType.socialRequired
    }

    this.setState({ TournamentType })
  }

  handleDismissAlert = () => {
    this.setState({ alertContent: '' })
  }

  /**
   * Render vista
   */
  render () {
    const { loading, alertContent, errors } = this.state
    const { t } = this.props
    if (loading) return <LoadingSVG message={alertContent} />
    return (
      <Wrapper>
        <div className='BaseContent'>
          <div className='BaseLeft' />

          <div className='BaseRight'>
            <div className='BaseRight__Form'>
              <Jumbotron>
                <h1>Registro nuevo Tipo de Torneo</h1>
                <p>
                  Ingrese todos los datos del formulario para inscribir un nuevo
                  tipo de Torneo.
                </p>
                <FormTournament
                  {...this.props}
                  {...this.state}
                  onSubmit={this.onSubmit}
                  onChange={this.onChange}
                  handleDismissAlert={this.handleDismissAlert}
                  onChangeModalToggle={this.onChangeModalToggle}
                />

                <div className='BaseRight__Bottom__actions'>
                  <Button
                    id='btn_Tournament1'
                    disabled={loading || errors}
                    type='submit'
                    text={t('Crear Tipo Torneo')}
                    state='primary'
                    form='FormCreate'
                    className='btn-register'
                    style={{ margin: 'auto' }}
                  />
                </div>
              </Jumbotron>
            </div>
          </div>
        </div>
      </Wrapper>
    )
  }
}

// @Proptypes
TournamentTypeCreateView.propTypes = {
  loggedUser: PropTypes.object
}

/*
  @Store Connection: connect
  @Export Component
*/

const mapStateToProps = state => ({
  loggedUser: state.loggedUser,
  countries: state.countries
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      TournamentTypeCreate,
      getUser
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TournamentTypeCreateView))

// @Styles
const Wrapper = styled.div.attrs({ className: 'Tournament' })`
  height: inherit;
`
