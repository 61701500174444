// @External Dependencies
import React, { Component } from 'react'
// import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import {
  Jumbotron,
  Table,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'

// @Dependencies
import {
  getUser,
  getTournamentRoundStanding,
  TournamentFinish
} from '../../../Redux/Actions'
import { LoadingSVG, Button, Avatar } from '../../../Components/UI'
import { axiosAbort } from '../../../Services'
import TournamentMenu from '../TournamentMenu'
import TournamentInfo from './TournamentInfo'

import StandingPrint from './standing_print'
import { StoreAlertPoints } from './StoreAlertPoints'

// @Component
class TournamentRoundStandingView extends Component {
  state = {
    alertContent: '',
    buttonBack: '',
    loading: true,
    havePermissions: false,
    modalEnd: false,
    tournament: {},
    round: {},
    standing: [],
    races: [],
    renderPdf: this.props.match.params.hasOwnProperty('pdf'),
    navigation: {
      prev: {},
      next: {}
    }
  }

  componentDidMount () {
    this.searchStanding()
  }
  componentWillUnmount () {
    axiosAbort()
  }
  async searchStanding () {
    await this.props.getUser()
    const { getTournamentRoundStanding, match, loggedUser } = this.props
    const resolveStanding = await getTournamentRoundStanding(
      match.params.roundId
    )

    if (resolveStanding.status.success) {
      const roundInfo = resolveStanding.TournamentRound
      const tournament = resolveStanding.Tournament
      const races = tournament.Game.RaceGames.reduce((acc, race) => {
        acc.push({
          id: race.raceId,
          name: race.Race.name
        })
        return acc
      }, [])
      const rounds = tournament.TotalTournamentRounds
      const navigation = {
        prev: rounds[roundInfo.sortOrder - 2]
          ? rounds[roundInfo.sortOrder - 2]
          : {},
        next: rounds[roundInfo.sortOrder] ? rounds[roundInfo.sortOrder] : {}
      }
      const havePermissions =
        loggedUser.user && loggedUser.user.Store
          ? tournament.storeId === loggedUser.user.Store.id ||
            loggedUser.isAdmin
          : tournament.TournamentOrganizers.some(organizer => {
              if (loggedUser.user) {
                return organizer.personId === loggedUser.user.id
              }
              return false
            })
      this.setState({
        havePermissions,
        standing: resolveStanding.Standing,
        round: roundInfo,
        races,
        tournament,
        loading: false,
        navigation
      })
    } else {
      this.setState({
        buttonBack: '/tournaments',
        alertContent: `No se pudo encontrar el standing para la ronda.`
      })
    }
  }

  render () {
    const {
      alertContent,
      buttonBack,
      loading,
      renderPdf,
      round,
      standing
    } = this.state
    if (loading)
      return <LoadingSVG message={alertContent} buttonBack={buttonBack} />
    return renderPdf ? (
      <StandingPrint
        round={round}
        standing={standing}
        match={this.props.match}
      />
    ) : (
      this.viewRender()
    )
  }

  /**
   * Para terminar el torneo
   */
  toggleModalEnd = () => {
    this.setState({
      modalEnd: !this.state.modalEnd
    })
  }

  /**
   * Da por finalizado el torneo
   * - Se cambia el estado a 5 (si no lo está)
   * - Se procesan los datos del standing final y se publican en el ranking
   * - Se aplica el multiplicador del tipo de torneo a los resultados
   */
  endTournament = async () => {
    const { TournamentFinish, history } = this.props
    const { tournament } = this.state

    this.setState({
      loading: true,
      alertContent:
        'Estamos reportando los resultados de los jugadores al ranking.'
    })
    const response = await TournamentFinish(tournament.id)
    if (response.status.success) {
      this.setState({
        alertContent: response.status.detail
      })
      setTimeout(() => {
        history.push(response.Tournament.urlView)
      }, 2000)
    } else {
      this.setState({
        loading: false,
        alertContent: response.status.detail
      })
    }
  }

  viewRender () {
    const {
      tournament,
      round,
      havePermissions,
      standing,
      navigation,
      races
    } = this.state
    const { t, match } = this.props
    return (
      <Wrapper>
        <div className='BaseContent'>
          <div className='BaseLeft' />
          <div className='BaseRight'>
            <div className='BaseRight__Form'>
              <TournamentMenu
                isMobile={this.props.isMobile}
                tournament={tournament}
                havePermissions={havePermissions}
                match={match}
                t={t}
              />
              {
                <TournamentInfo
                  tournament={tournament}
                  isMobile={this.props.isMobile}
                />
              }
              <Jumbotron className='Round' id='pdfRender'>
                <nav>
                  {navigation.prev.id && (
                    <a
                      href={
                        navigation.prev.TournamentRoundStatus.id === 2
                          ? navigation.prev.urlStanding
                          : navigation.prev.urlView
                      }
                      className='pull-left'>
                      <i className='fa fa-chevron-left' />{' '}
                      {navigation.prev.name}
                    </a>
                  )}
                  {navigation.next.id && (
                    <a
                      href={
                        navigation.next.TournamentRoundStatus.id === 2
                          ? navigation.next.urlStanding
                          : navigation.next.urlView
                      }
                      className='pull-right'>
                      {navigation.next.name}{' '}
                      <i className='fa fa-chevron-right' />
                    </a>
                  )}
                </nav>
                <h3 className='display-5 text-center'>
                  Posiciones: {round.name}
                </h3>
                <p className='text-center'>
                  Estado Ronda: <b>{round.TournamentRoundStatus.name}</b>
                </p>
                <div className='buttons'>
                  {!this.props.isMobile && (
                    <a
                      href={`${round.urlStanding}/pdf`}
                      className='btn btn-primary'
                      target='_blank'
                      rel='noopener noreferrer'>
                      Imprimir
                    </a>
                  )}
                  <Button
                    text={'Ver emparejamientos'}
                    state='success'
                    link={round.urlView}
                  />
                </div>
                <Table
                  className={`table-bordered ${
                    this.props.isMobile ? 'table-responsive' : ''
                  }`}>
                  <thead>
                    <StoreAlertPoints
                      tournament={tournament}
                      isMobile={this.props.isMobile}
                    />
                    <tr>
                      <th>#</th>
                      <th colSpan={this.props.isMobile ? 1 : 2}>Jugador</th>
                      {tournament.statusId === 6 && <th>Raza</th>}
                      {!this.props.isMobile && <th>TOR</th>}
                      <th className='text-center'>Puntaje</th>
                      <th className='text-center'>OMW</th>
                      <th className='text-center'>PGW</th>
                      <th className='text-center'>OGW</th>
                      <th className='text-center'>Drop</th>
                    </tr>
                  </thead>
                  <tbody>
                    {standing.map((player, key) => {
                      const race = player.TournamentPlayer
                        ? races.find(
                            x => x.id === player.TournamentPlayer.raceId
                          )
                        : null
                      return (
                        <tr
                          key={player.id}
                          className={`Round__match ${
                            player.TournamentPlayer.drop ? 'text-danger' : ''
                          }`}>
                          <td className='text-center'> {key + 1}</td>
                          {!this.props.isMobile && (
                            <td>
                              <Avatar
                                person={player.TournamentPlayer.Person}
                                size={24}
                                fontSize={9}
                              />
                            </td>
                          )}
                          <td>
                            {player.TournamentPlayer.Person.fullName}{' '}
                            {round.TournamentRoundType.id === 2
                              ? `(${player.position})`
                              : ''}
                          </td>
                          {tournament.statusId === 6 && (
                            <td>{race ? race.name : ''}</td>
                          )}
                          {!this.props.isMobile && (
                            <td>{player.TournamentPlayer.Person.code}</td>
                          )}
                          <td className='text-center'>{player.points}</td>
                          <td className='text-center'>{player.omw}</td>
                          <td className='text-center'>{player.pgw}</td>
                          <td className='text-center'>{player.ogw}</td>
                          <td className='text-center'>
                            {player.TournamentPlayer.drop &&
                            player.TournamentPlayer.TournamentRoundDrop
                              ? player.TournamentPlayer.TournamentRoundDrop.name
                              : ''}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td
                        colSpan={this.props.isMobile ? 3 : 5}
                        className='text-right'
                      />
                      <td colSpan={4} className='text-left'>
                        <ul className='standing-definitions'>
                          <li>
                            <b>OMW</b>: Porcentaje de rondas ganadas por los
                            oponentes.
                          </li>
                          <li>
                            <b>PGW</b>: Porcentaje de partidas ganadas del
                            jugador.
                          </li>
                          <li>
                            <b>OGW</b>: Porcentaje de partidas ganadas por los
                            oponentes.
                          </li>
                          <li>
                            <b>Drop</b>: Ronda en que abandonó el evento.{' '}
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
                {/**
                 * Reportarmos el torneo
                 */
                havePermissions && tournament.TournamentStatus.id === 5 && (
                  <Card body className='text-center'>
                    <h2>El torneo ha concluido. Ahora puedes reportarlo.</h2>
                    <p>
                      Recuerda que una vez reportado no podrás hacer cambios
                    </p>
                    <Button
                      text={
                        tournament.TournamentStatus.id !== 5
                          ? 'Terminar Torneo'
                          : 'Reportar torneo'
                      }
                      state='danger'
                      onClick={() => this.toggleModalEnd()}
                      style={{ margin: 'auto' }}
                    />

                    {/* Modal REPORTAR TORNEO */}
                    <Modal
                      isOpen={this.state.modalEnd}
                      toggle={() => this.toggleModalEnd()}>
                      <ModalHeader toggle={() => this.toggleModalEnd()}>
                        Gestión de Torneo
                      </ModalHeader>
                      <ModalBody>
                        <p>
                          Al confirmar <b>"Reportar Torneo"</b> se dará por
                          finalizado. Y no podrás hacer cambios en el evento.
                        </p>
                        <p>
                          Los puntajes obtenidos por los jugadores serán
                          reportados al ranking general.
                        </p>
                        <span className='text-info'>
                          Torneo {tournament.TournamentType.name}
                        </span>
                        <ul>
                          <li>
                            Por cada ronda ganada sumará{' '}
                            {tournament.TournamentType.winnerPoints *
                              tournament.TournamentType.multiplier}{' '}
                            puntos al ranking
                          </li>
                          <li>
                            Por cada ronda perdida sumará{' '}
                            {tournament.TournamentType.loserPoints *
                              tournament.TournamentType.multiplier}{' '}
                            puntos al ranking
                          </li>
                          <li>
                            Por cada ronda empatada sumará{' '}
                            {tournament.TournamentType.drawPoints *
                              tournament.TournamentType.multiplier}{' '}
                            puntos al ranking
                          </li>
                        </ul>
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          state='default'
                          onClick={() => this.toggleModalEnd()}
                          text='Cancelar'
                        />
                        <Button
                          state='success'
                          onClick={this.endTournament}
                          text={'Reportar Torneo'}
                        />
                      </ModalFooter>
                    </Modal>
                  </Card>
                )}
              </Jumbotron>
            </div>
          </div>
        </div>
      </Wrapper>
    )
  }
}

// @Proptypes
TournamentRoundStandingView.propTypes = {
  loggedUser: PropTypes.object
}

/**
 * @Store Connection: connect
 * @Export Component
 */
const mapStateToProps = state => ({
  loggedUser: state.loggedUser
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTournamentRoundStanding,
      TournamentFinish,
      getUser
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TournamentRoundStandingView))

// @Styles
const Wrapper = styled.div.attrs({ className: 'RoundDetails' })`
  height: inherit;
  .standing-definitions {
    font-size: 12px;
  }
`
