// @External Dependencies
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Jumbotron } from 'reactstrap'

// @Dependencies
import { GameFind, GameUpdate, getUser, UploadImage } from '../../Redux/Actions'
import { Button, LoadingSVG } from '../../Components/UI'
import { axiosAbort } from '../../Services'

import FormGame from './Form'

// @Component
class GameUpdateView extends Component {
  state = {
    alertContent: '',
    alertState: 'warning',
    loading: true,
    errors: true,
    Game: {
      id: 0,
      name: '',
      description: '',
      image: ''
    },
    image: ''
  }

  /**
   * - Al iniciar busca los juegos, formatos, tipos de torneo
   * - Si no tengo permisos para crear torneos entonces mostramos alerta y enviamos al home
   */
  async componentDidMount () {
    await this.props.getUser()
    if (!this.props.loggedUser.isAdmin) {
      this.setState({
        loading: true,
        loadingMessage: 'Solo administradores pueden ver esta sección.',
        buttonBack: '/'
      })
    } else {
      const gameId = this.props.match.params.id
      const { Game, status } = await this.props.GameFind(gameId)
      if (status.success) {
        this.setState({
          Game,
          errors: false,
          loading: false,
          image: Game.urlImage
        })
      } else {
        this.props.history.push('/games')
      }
    }
  }

  componentWillUnmount () {
    axiosAbort()
  }

  onSubmit = async event => {
    event.preventDefault()
    const { history } = this.props
    const { Game } = this.state

    this.setState({
      alertContent: 'Estamos actualizando el juego',
      loading: true
    })

    let { errors } = this.state
    /**
     * Si no hay errores de validación entonces cremos el Torneo
     */
    if (!errors) {
      const resolver = await this.props.GameUpdate(Game)
      if (!resolver.status.success) {
        this.setState({
          alertContent: resolver.status.detail
        })
        setTimeout(() => {
          this.setState({ loading: false })
        }, 2000)
      } else {
        this.setState({
          alertContent: resolver.status.name,
          alertState: 'success',
          errors: true
        })
        setTimeout(() => {
          history.push('/games')
        }, 3000)
      }
    } else {
      this.setState({
        errors,
        alertContent: 'Hay errores en el formulario',
        loadingRegister: false
      })
    }
  }

  /**
   * Controla los cambios de estados de los inputs
   */
  onChange = async e => {
    const { Game } = this.state
    const target = e.target

    let { errors } = this.state
    if (target && target.id === 'name') {
      Game.name = target.value.trim()
      errors = !(Game.name.length > 0)
    }
    if (target && target.id === 'description') {
      Game.description = target.value.trim()
      errors = !(Game.description.length > 0)
    }
    this.setState({ Game, errors })
  }

  handleDismissAlert = () => {
    this.setState({ alertContent: '' })
  }

  upload = e => {
    const files = e.target.files
    const file = files[0]
    if (['image/jpeg', 'image/png'].includes(file.type)) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async e => {
        const { status, Game } = await this.props.UploadImage({
          file: e.target.result,
          name: files[0].name,
          type: 'game.image',
          gameId: this.state.Game.id
        })

        if (status.success) {
          this.setState({
            image: Game.urlImage
          })
        }
      }
    } else {
      alert('Error en formato, sólo se permiten JPG o PNG')
    }
  }

  /**
   * Render vista
   */
  render () {
    const { loading, alertContent, errors } = this.state
    const { t } = this.props
    if (loading) return <LoadingSVG message={alertContent} />
    return (
      <Wrapper>
        <div className='BaseContent'>
          <div className='BaseLeft' />
          <div className='BaseRight'>
            <Link to='/games' className='padding-20'>
              <i className='fa fa-arrow-left' /> Listado de Juegos
            </Link>
            <div className='BaseRight__Form'>
              <Jumbotron>
                <h1>Actualizando Juego</h1>
                <p>
                  Ingrese todos los datos del formulario para actualizar los
                  datos del Juego.
                </p>
                <FormGame
                  {...this.props}
                  {...this.state}
                  onSubmit={this.onSubmit}
                  onChange={this.onChange}
                  handleDismissAlert={this.handleDismissAlert}
                  uploadImage={this.upload}
                  isEdit
                />

                <div className='BaseRight__Bottom__actions'>
                  <Button
                    id='btn_Tournament1'
                    disabled={loading || errors}
                    type='submit'
                    text={t('Actualizar el Juego')}
                    state='primary'
                    form='FormCreate'
                    className='btn-register'
                    style={{ margin: 'auto', width: '40%' }}
                  />
                </div>
              </Jumbotron>
            </div>
          </div>
        </div>
      </Wrapper>
    )
  }
}

// @Proptypes
GameUpdateView.propTypes = {
  loggedUser: PropTypes.object
}

/*
  @Store Connection: connect
  @Export Component
*/

const mapStateToProps = state => ({
  loggedUser: state.loggedUser,
  countries: state.countries
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      GameUpdate,
      GameFind,
      UploadImage,
      getUser
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(GameUpdateView))

// @Styles
const Wrapper = styled.div.attrs({ className: 'Tournament' })`
  height: inherit;
`
