// @External Dependencies
import React, { Component } from 'react'
// import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

// @Dependencies
import bgLogin from '../../../assets/img/login/Krishna.jpg'
import { UserChangePasswd, UserLogin, getUser } from '../../Redux/Actions'
import { Alert, Button, Input } from '../../Components/UI'
import { axiosAbort } from '../../Services'

// @Component
class UserRecover extends Component {
  state = {
    alertContent: '',
    alertState: 'warning',
    loading: false,
    validatePasswd: false,
    userId: 0,
    token: '',
    passwd: ''
  }

  componentDidMount () {
    const { match } = this.props
    const { token, userId } = match.params
    this.setState({ token, userId: parseInt(userId, 10) })
  }

  componentWillUnmount () {
    axiosAbort()
  }

  submitForm = async event => {
    event.preventDefault()
    const { history } = this.props
    const { userId, token } = this.state
    const form = new window.FormData(event.target)
    const passwd = form.get('passwd').trim()

    let alertState = 'info'
    let loading = true
    this.setState({
      alertContent: 'Cambiando contraseña...',
      alertState,
      loading
    })

    const changePasswd = await this.props.UserChangePasswd({
      userId,
      token,
      passwd
    })

    let alertContent = ''
    if (!changePasswd.status.success) {
      alertContent = changePasswd.status.detail
      alertState = 'danger'
      loading = false
    } else {
      alertContent = changePasswd.status.name
      alertState = 'success'
      loading = true
    }

    this.setState({
      alertContent,
      alertState,
      loading
    })

    /**
     * Si cambió la clave correctamente enviamos al login
     */
    changePasswd.status.success &&
      setTimeout(() => {
        history.push('/login')
      }, 3000)
  }

  /**
   * onChange de inputs
   */
  changePasswd = e => {
    const target = e.target
    var mediumRegex = new RegExp(
      '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})'
    )

    const validatePasswd = mediumRegex.test(target.value)
    this.setState({
      passwd: target.value,
      validatePasswd
    })

    let alertContent
    let alertState = 'warning'

    if (validatePasswd) {
      alertContent = 'Contraseña segura'
      alertState = 'success'
    } else {
      alertContent = [
        'Contraseña poco segura, debe contener al menos:',
        '- 1 mayúscula',
        '- 1 minúscula',
        '- numéricos',
        '- o un largo mínimo de 8 caracteres.'
      ].join('<br />')
    }
    this.setState({
      alertContent,
      alertState,
      validatePasswd
    })
  }

  render () {
    const { loading, alertContent, alertState, validatePasswd } = this.state
    const { t } = this.props
    return (
      <Wrapper>
        <div className='BaseContent'>
          <div className='BaseLeft' />

          <div className='BaseRight'>
            <div className='BaseRight__Form'>
              <h2>{t('Recuperar contraseña')}</h2>
              <p>{t('Ingresar tu nueva contraseña.')}</p>
              <p>
                Una vez cambiada la contraseña podrás ingresar con tu Nick /
                Código TOR / Email y la contraseña que definas ahora.
              </p>
              <form
                onSubmit={this.submitForm}
                acceptCharset='utf-8'
                id='UserLoginForm'>
                {alertContent.length > 0 && (
                  <Alert color={alertState} close={this.handleDismissAlert}>
                    {ReactHtmlParser(alertContent)}
                  </Alert>
                )}
                <Input
                  icon='lock'
                  disabled={loading}
                  inputSize={12}
                  name='passwd'
                  placeholder={t('Ingresa tu nueva contraseña')}
                  onChange={this.changePasswd}
                  type='password'
                  required
                />
              </form>

              <div className='BaseRight__Bottom__actions'>
                <Button
                  id='btn_login1'
                  disabled={!validatePasswd || loading}
                  type='submit'
                  text={t('Recuperar')}
                  state='primary'
                  form='UserLoginForm'
                  className='btn-login'
                />
                <Link to='/login' className='btn btn-link'>
                  {t('Ingresar')}
                </Link>
                <Link to='/register' className='btn btn-link'>
                  {t('Registrarme')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    )
  }
}

// @Proptypes
UserRecover.propTypes = {
  loggedUser: PropTypes.object
}

/*
  @Store Connection: connect
  @Export Component
*/

const mapStateToProps = state => ({ loggedUser: state.loggedUser })
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      UserChangePasswd,
      UserLogin,
      getUser
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UserRecover))

// @Styles
const Wrapper = styled.div.attrs({ className: 'Login' })`
  height: inherit;

  .RecoverContent {
    display: flex;
    width: 100%;
  }

  .RecoverLeft {
    align-items: center;
    background: url(${bgLogin}) 50% 0 no-repeat / cover;
    display: flex;
    height: inherit;
    padding: 0 30px;
    position: relative;
    width: 50%;

    &:before {
      background-color: rgba(0, 0, 0, 0.35);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    div {
      flex-basis: 100%;
      z-index: 1;
    }

    img {
      position: absolute;
      top: 0;
      width: 170px;
    }

    h1,
    h2,
    p {
      color: #fff;
      text-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    }

    h1 {
      font-size: 70px;
      font-weight: 300;
      letter-spacing: 6px;
      line-height: 55px;
      margin-bottom: 0;
      margin-left: -5px;
      text-transform: uppercase;
    }

    hr {
      border-top: 2px solid white;
      margin: 20px 0;
      opacity: 0.5;
    }

    h2 {
      font-size: 1.4286rem;
      letter-spacing: 1px;
      margin-bottom: 0;
    }
  }

  .RecoverRight {
    display: flex;
    flex-direction: column;
    height: inherit;
    justify-content: space-around;
    padding: 0 50px;
    position: relative;
    width: 50%;

    &__Form {
      form {
        margin-top: 60px;
      }

      .username {
        & > div {
          display: flex;
          width: 100%;

          & > div {
            width: 100%;
          }

          .Select {
            margin-right: 7.5px;

            select {
              border-radius: 4px;
            }
          }
          .container-input {
            width: 100%;
          }
        }
      }

      .InputUI {
        input {
          border-radius: 4px;
        }
      }

      i.fa {
        font-size: 1.2857rem;
      }

      .form-group {
        margin-bottom: 15px;
      }

      h2 {
        color: #7c7781;
        font-size: 26px;
        margin-top: 60px;
      }
      h4 {
        font-size: 16px;
        font-weight: 400;
      }
    }

    &__Bottom {
      bottom: 20px;
      color: #c9c9d3;
      display: flex;
      justify-content: space-between;
      left: 50px;
      position: absolute;
      right: 50px;

      div {
        align-items: center;
        display: flex;
      }

      span {
        font-size: 0.8571rem;

        &:last-child {
          float: right;
          vertical-align: bottom;
        }
      }

      img {
        margin-left: 5px;
        max-width: 60px;
      }

      &__actions {
        button {
          margin: 20px 0px;
        }
        a {
          margin-right: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 1023px) {
    .RecoverContent {
      flex-direction: column;
      height: 100%;
    }

    .RecoverLeft,
    .RecoverRight {
      width: 100%;
    }

    .language {
      max-width: 100%;
      position: relative;
    }

    .RecoverLeft {
      align-items: flex-end;
      background-position: 50% 0;
      background-size: cover;
      min-height: 490px;
      padding-bottom: 20px;
    }

    .RecoverRight {
      align-items: center;
      justify-content: flex-start;
      min-height: 100%;
      padding-bottom: 70px;

      &__Form,
      &__Apps {
        width: 350px;
      }

      & > div {
        width: 500px;
      }

      &__Form {
        form,
        h2 {
          margin-top: 30px;
        }
      }

      &__Apps {
        margin-top: 30px;
      }

      &__Bottom {
        border-top: 1px solid #c9c9c9;
        margin-top: 30px;
        padding: 20px 30px;
        position: static;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .RecoverRight {
      min-height: 100vh;
    }
    .language {
      position: absolute;
    }
  }

  @media screen and (max-width: 540px) {
    .language {
      justify-content: center;
      padding: 20px 0;
    }
    .RecoverContent {
      display: block;
      height: auto;
    }

    .RecoverLeft,
    .RecoverRight {
      padding-left: 30px;
      padding-right: 30px;
    }

    .RecoverLeft {
      background: none;
      padding-bottom: 0;
      min-height: auto;

      img {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }

      &:before,
      h1,
      hr,
      h2 {
        display: none;
      }
    }

    .btn-login {
      margin: 0 auto;
    }

    .RecoverRight {
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 0;

      &__Form {
        width: 100%;

        .username {
          & > div {
            display: block;
          }
        }
      }

      & > div {
        width: 100%;
      }

      &__Apps {
        margin-top: 30px;
      }

      &__Bottom {
        align-items: center;
        border-color: #e4e4e4;
        height: auto;
        margin-top: 20px;
        padding: 20px 0;
        & > span {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  @media screen and (max-width: 320px) {
    .RecoverLeft,
    .RecoverRight {
      padding-left: 15px;
      padding-right: 15px;
    }

    .RecoverLeft {
      img {
        width: 130px;
      }
    }
    .btn-login {
      width: 100%;
    }
    .RecoverRight {
      &__Form {
        h2,
        form {
          margin-top: 30px;
        }
      }
    }
  }
`
