// @External Dependencies
import React, { Component } from 'react'
// import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Jumbotron } from 'reactstrap'
import { Link } from 'react-router-dom'

// @Dependencies
import {
  SeasonFind,
  SeasonUpdate,
  getGames,
  getUser
} from '../../Redux/Actions'
import { Button, LoadingSVG } from '../../Components/UI'
import { axiosAbort } from '../../Services'

import FormSeason from './Form'

// @Component
class SeasonUpdateView extends Component {
  state = {
    alertContent: '',
    alertState: 'warning',
    loading: true,
    errors: true,
    Games: [],
    Season: {
      name: '',
      description: '',
      gameId: 0,
      isActive: false,
      startDate: '',
      endDate: ''
    }
  }

  /**
   * - Al iniciar busca los juegos, formatos, tipos de torneo
   * - Si no tengo permisos para crear torneos entonces mostramos alerta y enviamos al home
   */
  async componentDidMount () {
    await this.props.getUser()
    if (!this.props.loggedUser.isAdmin) {
      this.setState({
        loading: true,
        loadingMessage: 'Solo administradores pueden ver esta sección.',
        buttonBack: '/'
      })
    } else {
      const seasonId = this.props.match.params.id
      const { Season, status } = await this.props.SeasonFind(seasonId)
      const Games = await this.props.getGames()
      if (status.success) {
        Season.gameId = Season.Game.id
        Season.isActive = Season.isActive === 1
        delete Season.Game
        this.setState({
          Games,
          Season,
          errors: false,
          loading: false
        })
      } else {
        this.props.history.push('/admin/seasons')
      }
    }
  }

  componentWillUnmount () {
    axiosAbort()
  }

  onSubmit = async event => {
    event.preventDefault()
    const { SeasonUpdate, history } = this.props
    const { Season } = this.state

    this.setState({
      alertContent: 'Estamos actualizando el tipo de torneo...',
      loading: true
    })

    let { errors } = this.state
    /**
     * Si no hay errores de validación entonces cremos el Torneo
     */
    if (!errors) {
      const resolver = await SeasonUpdate(Season)
      if (!resolver.status.success) {
        this.setState({
          alertContent: resolver.status.detail
        })
        setTimeout(() => {
          this.setState({ loading: false })
        }, 2000)
      } else {
        /**
         * Si se registra correctamente el torneo entonces redireccionamos a su detalle
         */
        this.setState({
          alertContent: resolver.status.name,
          alertState: 'success',
          errors: true
        })
        setTimeout(() => {
          history.push('/admin/seasons')
        }, 3000)
      }
    } else {
      this.setState({
        errors,
        alertContent: 'Hay errores en el formulario',
        loadingRegister: false
      })
    }
  }

  /**
   * Controla los cambios de estados de los inputs
   */
  onChange = async e => {
    const { Season } = this.state
    const target = e.target

    let { errors } = this.state
    if (target && target.id === 'name') {
      Season.name = target.value.trim()
      errors = !(Season.name.length > 0)
    }

    if (target && target.id === 'description') {
      Season.description = target.value.trim()
      errors = !(Season.description.length > 0)
    }

    if (target && target.id === 'gameId') {
      Season.gameId = parseInt(target.value, 10)
      errors = !(Season.gameId > 0)
    }

    this.setState({ Season, errors })
  }

  onChangeToggle = e => {
    e.preventDefault()
    const target = e.target
    const { Season } = this.state

    if (target.id === 'isActive') {
      Season.isActive = !Season.isActive
    }

    this.setState({ Season })
  }

  handleDismissAlert = () => {
    this.setState({ alertContent: '' })
  }

  /**
   * Controla los cambios de los Datapicker
   */
  changeDate = (e, name) => {
    let key, value
    if (name) {
      key = name
      value = e
    } else {
      key = e.target.name
      value = Number(e.target.value)
      if (e.target.name === 'commentLeaving') {
        value = e.target.value
      }
    }
    const { Season } = this.state
    if (key === 'startDate') {
      Season.startDate = value
    }
    if (key === 'endDate') {
      Season.endDate = value
    }
    this.setState({ Season })
  }

  /**
   * Render vista
   */
  render () {
    const { loading, errors, alertContent } = this.state
    const { t } = this.props
    if (loading) return <LoadingSVG message={alertContent} />
    return (
      <Wrapper>
        <div className='BaseContent'>
          <div className='BaseLeft' />

          <div className='BaseRight'>
            <div className='BaseRight__Form'>
              <Jumbotron>
                <Link to='/admin/seasons'>
                  <i className='fa fa-chevron-left' /> Volver
                </Link>
                <h1>{t('Actualizando Temporada')}</h1>
                <p>
                  Ingrese todos los datos del formulario para actualizar los
                  datos de la temporada de juego.
                </p>
                <p>
                  Recordar que solo puede haber una temporada por juego activa a
                  la vez. Y no pueden haber juegos sin temporadas activas.
                </p>
                <FormSeason
                  {...this.props}
                  {...this.state}
                  onSubmit={this.onSubmit}
                  onChange={this.onChange}
                  handleDismissAlert={this.handleDismissAlert}
                  onChangeToggle={this.onChangeToggle}
                  changeDate={this.changeDate}
                />

                <div className='BaseRight__Bottom__actions'>
                  <Button
                    disabled={loading || errors}
                    type='submit'
                    text={t('Actualizar la temporada')}
                    state='primary'
                    form='SeasonForm'
                    className='btn-register'
                    style={{ margin: 'auto', width: '40%' }}
                  />
                </div>
              </Jumbotron>
            </div>
          </div>
        </div>
      </Wrapper>
    )
  }
}

// @Proptypes
SeasonUpdateView.propTypes = {
  loggedUser: PropTypes.object
}

/*
  @Store Connection: connect
  @Export Component
*/

const mapStateToProps = state => ({
  loggedUser: state.loggedUser,
  countries: state.countries
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      SeasonUpdate,
      SeasonFind,
      getGames,
      getUser
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SeasonUpdateView))

// @Styles
const Wrapper = styled.div.attrs({ className: 'Season' })`
  height: inherit;
`
