export const states = {
  PROGRAMADO: 1,
  INSCRIPCIONES: 2,
  INICIADO: 3,
  PROCESO: 4,
  TERMINADO: 5,
  REPORTADO: 6,
  CANCELADO: 7,
  ANULADO: 8
}

// Estado cursos activos
export const ACTIVOS = [
  states.PROGRAMADO,
  states.INSCRIPCIONES,
  states.INICIADO,
  states.PROCESO,
  states.TERMINADO,
  states.REPORTADO
]

// Estados todos los cursos.
export const TODOS = [...ACTIVOS, states.CANCELADO, states.ANULADO] // Pasados
